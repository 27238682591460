import _styled from "styled-components";
const ProgressContainer = _styled.div({
  "height": "0.625rem",
  "width": "100%",
  "borderRadius": "9999px",
  "backgroundColor": "#8CAEBA33"
});
type ProgressProps = {
  value: number;
};
export const Progress: React.FC<ProgressProps> = ({
  value
}) => <ProgressContainer>
    <div css={{
    "height": "0.625rem",
    "borderRadius": "9999px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(77 114 128 / var(--tw-bg-opacity))"
  }} style={{
    width: `${Math.min(value, 100)}%`
  }} />
  </ProgressContainer>;