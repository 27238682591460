import { FileType, getFileTypeExtensions, getFileTypeMimeType } from '@kindo/universal';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { tv, type VariantProps } from 'tailwind-variants';
const style = tv({
  base: 'flex-col h-full w-full absolute top-0 right-0 z-10 bg-paper items-center justify-center cursor-pointer',
  variants: {}
});
interface FileUploaderProps extends VariantProps<typeof style> {
  acceptedFileTypes: FileType[];
  onChange: (files: File[]) => void;
  className?: string;
  onLoad?: (ref: React.RefObject<HTMLInputElement>) => void;
}
export const FileUploader = ({
  className,
  onChange,
  acceptedFileTypes,
  onLoad
}: FileUploaderProps) => {
  const [display, setDisplay] = useState<'hidden' | 'flex'>('hidden');
  const {
    getRootProps,
    getInputProps,
    inputRef
  } = useDropzone({
    onDrop: <T extends File,>(files: T[]) => {
      onChange(files);
      setDisplay('hidden');
    },
    // https://react-dropzone.js.org/#src
    // https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker#examples
    // { [mimeType: string]: [extensions: string[]] }
    accept: Object.fromEntries(acceptedFileTypes.map(fileType => [[getFileTypeMimeType(fileType)], getFileTypeExtensions(fileType).map(ext => `.${ext}`)]) ?? [])
  });
  useEffect(() => {
    onLoad?.(inputRef);
    const dragover = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDisplay('flex');
    };
    document.body.addEventListener('dragover', dragover);
    const dragleave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDisplay('hidden');
    };
    document.body.addEventListener('dragleave', dragleave);
    return () => {
      document.body.removeEventListener('dragover', dragover);
      document.body.removeEventListener('dragleave', dragleave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div
  // eslint-disable-next-line react/jsx-props-no-spreading
  {...getRootProps()} className={clsx(style({
    className
  }), display)}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <div css={{
      "fontSize": "1.125rem",
      "lineHeight": "1.75rem"
    }}>Click to upload or drag and drop.</div>
    </div>;
};
export default FileUploader;