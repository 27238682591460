import _styled from "styled-components";
import { ContentListSort } from '@kindo/api';
import { INTEGRATION_DISPLAY_NAMES, isAudioFileType, isIntegration, isVideoFileType } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { Progress } from '../../Progress';
import { FileResource } from './AddFileModal.types';
import { FileUploadStatus } from './FileUploader';
import { Color, Icon, IconButton, IconButtonType, TableColumn, TableRow, Typography } from '~/components/core';
import { ContentIcon } from '~/components/shared';
import { getContentDirectFetchUrl } from '~/constants';
export type FileResourceWithTableMetadata = FileResource & {
  hasPlaintextTranscription: boolean;
  isSupportedByModel: boolean;
  isTooLargeToDownloadFromMerge: boolean;
  isUploadedToCorrespondingCloud: boolean;
};
export const shouldShowFileTranscriptionDownload = (file: FileResourceWithTableMetadata): {
  isTranscriptionReady: boolean;
  shouldShowTranscriptionDownload: boolean;
} => {
  const shouldShowTranscriptionDownload = !!file.fileType && (isVideoFileType(file.fileType) || isAudioFileType(file.fileType));
  return {
    shouldShowTranscriptionDownload,
    isTranscriptionReady: file.hasPlaintextTranscription
  };
};
const ellipsis = (text: string, max = 35) => {
  if (text.length > max) {
    return text.substring(0, 20) + '...' + text.substring(text.length - 10, text.length);
  }
  return text;
};
export const FILE_TABLE_COLUMNS: TableColumn<ContentListSort>[] = [{
  title: 'NAME',
  width: {
    "width": "50%"
  },
  sortKey: ContentListSort.FILE_NAME
}, {
  title: 'TYPE',
  width: {
    "width": "8.333333%"
  },
  sortKey: ContentListSort.FILE_TYPE
}, {
  title: 'SOURCE',
  width: {
    "width": "12.5%"
  },
  // 1/8
  sortKey: ContentListSort.INTEGRATION
}, {
  title: 'UPLOADED',
  width: {
    "width": "12.5%"
  },
  // 1/8
  sortKey: ContentListSort.CREATED_AT
}, {
  title: '',
  width: {
    "width": "8.333333%"
  }
}];
export const getFileUploadRow = (upload: FileUploadStatus): React.ReactElement => <tr key={upload.file.name} css={{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 239 226 / var(--tw-bg-opacity))"
}} title={`Uploading ${upload.file.name} ${upload.progress}%`}>
    <td />
    <td css={{
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  }}>
      <span>{ellipsis(upload.file.name)}</span>
    </td>
    <td css={{
    "padding": "0.5rem"
  }} colSpan={4}>
      <Progress value={upload.progress} />
    </td>
  </tr>;
const FileSourceContainer = _styled.div`
  ${{
  "display": "flex",
  "alignItems": "center",
  "gap": "0.25rem"
}}
`;

// Determine the tooltip message and text color based on file status
const getFileTooltipAndColor = (file: FileResourceWithTableMetadata) => {
  // If the file is both "not supported by the model" and "not ready for use",
  // prioritize the "not supported by the model" status for visual indication
  if (!file.isSupportedByModel) {
    return {
      toolTip: 'File type not supported by the model',
      disabledTextColor: Color.LAVENDER
    };
  }
  if (file.isTooLargeToDownloadFromMerge) {
    return {
      toolTip: 'File is too large to download',
      disabledTextColor: Color.DISABLED
    };
  }
  if (!file.isUploadedToCorrespondingCloud) {
    return {
      toolTip: 'File not yet ready for use',
      disabledTextColor: Color.DISABLED
    };
  }
  return {};
};
export const getFileRow = (file: FileResourceWithTableMetadata): TableRow => {
  const {
    shouldShowTranscriptionDownload,
    isTranscriptionReady
  } = shouldShowFileTranscriptionDownload(file);
  const isDisabled = !file.isUploadedToCorrespondingCloud || !file.isSupportedByModel;
  const {
    toolTip,
    disabledTextColor
  } = getFileTooltipAndColor(file);
  const source = isIntegration(file.source) ? INTEGRATION_DISPLAY_NAMES[file.source] : file.source;
  return {
    disabled: isDisabled,
    disabledTextColor,
    toolTip,
    key: file.id,
    height: {
      "height": "72px"
    },
    cells: [file.name, file.fileType,
    // Source
    <FileSourceContainer key={file.id}>
        <ContentIcon contentSource={file.source} />
        <Typography color={isDisabled ? disabledTextColor : undefined}>
          {source}
        </Typography>
      </FileSourceContainer>,
    // Uploaded (createdAt)
    formatDistanceToNowStrict(new Date(file.createdAt), {
      addSuffix: true
    }), shouldShowTranscriptionDownload ? <IconButton disabled={!isTranscriptionReady} href={getContentDirectFetchUrl({
      contentId: file.id,
      isDownload: true,
      isPlaintext: true
    })} icon={Icon.EDIT_FILE} title="Download Transcription" type={IconButtonType.OUTLINED} /> : undefined]
  };
};